import React from "react";
import styled from "styled-components";
import logo from "../../../assets/images/iso-aden.png";

const Navbar = () => {
    return (
        <NavbarWrapper>
            <img height='35px' src={logo} alt='Aden' />
        </NavbarWrapper>
    );
};

export default Navbar;

const NavbarWrapper = styled.nav`
    width: 100%;
    height: 60px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    position: fixed;
    z-index: 9999;
`;
