import styled from "styled-components";

export const Container = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 2rem;
    width: 100%;
    padding: 2rem;
    gap: 1rem;
`;

export const Image = styled.img`
    width: 40%;

    @media screen and (max-width: 768px) {
        width: 80%;
    }
`;

export const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 700;
    color: #b31d15;
    margin: 0;
`;

export const SubTitle = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    color: #616161;
    line-height: 150%;

    a {
        color: #b31d15;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
`;

export const Token = styled.div`
    height: 1px;
    overflow: hidden;
    visibility: hidden;
    margin: -1rem 0;
`;
