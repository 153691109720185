import React from "react";
import { createTheme, StylesProvider, ThemeProvider } from "@material-ui/core";

export function MaterialThemeProvider(props) {
    const theme = createTheme({
        typography: {
            fontFamily: ["Lato"].join(","),
            fontSize: 16,
            button: {
                textTransform: "none",
                fontWeight: "bold",
            },
        },
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: "#B31D15",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            secondary: {
                // light: will be calculated from palette.primary.main,
                main: "#616161",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            muted: {
                main: "#a8a8a8",
            },
            error: {
                // light: will be calculated from palette.primary.main,
                main: "#EB5757",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
        },
        props: {
            // Name of the component ⚛️
            MuiButtonBase: {
                // The properties to apply
                disableRipple: false, // No more ripple, on the whole application 💣!
            },

            // Set default elevation to 1 for popovers.
            MuiPopover: {
                elevation: 1,
            },
        },
    });
    const { children } = props;

    return (
        <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StylesProvider>
    );
}
