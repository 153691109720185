import React from "react";
import { Redirect, Switch } from "react-router-dom";
import GenericError from "./pages/GenericError";
import NotFound from "./pages/NotFound";
import ServerError from "./pages/ServerError";
import PublicRoute from "./routes/PublicRoute";

const Router = () => {
    return (
        <Switch>
            {/* <PublicRoute path='/:tipo(certificado|constancia)/:id' component={Constancy} /> */}
            <PublicRoute path='/server-error' component={ServerError} />
            <PublicRoute path='/:error_id' component={GenericError} />
            <PublicRoute path='/not-found' component={NotFound} />
            <Redirect path='*' to='/not-found' exact />
        </Switch>
    );
};

export default Router;
