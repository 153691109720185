import React from "react";

import { Button } from "@material-ui/core";
import { ButtonContainer, Container, Image, SubTitle, Title } from "../styles/CommonComponents";

import image from "../assets/images/adenibs/Error404.svg";

const NotFound = () => {
    return (
        <Container>
            <Image src={image} alt='Pagina no encontrada' />
            <Title>Página no encontrada</Title>
            <SubTitle>
                No pudimos encontrar la página que buscabas. Por favor, verifica que la URL esté bien escrita e
                inténtalo de nuevo. <br /> Si el error persiste, puedes contactarte con nuestro Centro de Ayuda.
            </SubTitle>
            <ButtonContainer>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        window.$crisp.push(["do", "chat:open"]);
                    }}
                >
                    Abrir chat de soporte
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default NotFound;
