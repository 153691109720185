import React from "react";
import { Button } from "@material-ui/core";

import image from "../assets/images/adenibs/Error500.svg";
import { ButtonContainer, Container, Image, SubTitle, Title, Token } from "../styles/CommonComponents";

const ServerError = () => {
    return (
        <Container>
            <Image src={image} alt='Pagina no encontrada' />
            <Title>Ooops.. Parece que algo salió mal</Title>
            <Token>::CLOUDFLARE_ERROR_500S_BOX::</Token>
            <SubTitle>
                Perdona las molestias, estamos trabajando para solucionarlo. <br /> Intenta recargar esta página o
                siéntete libre de contactarnos si el problema persiste.
            </SubTitle>
            <ButtonContainer>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => {
                        window.$crisp.push(["do", "chat:open"]);
                    }}
                >
                    Abrir chat de soporte
                </Button>
            </ButtonContainer>
        </Container>
    );
};

export default ServerError;
