import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { Button } from "@material-ui/core";

import image from "../assets/images/adenibs/ErrorGenerico.svg";
import error500 from "../assets/images/adenibs/Error500.svg";

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/styles";
import { useParams } from "react-router";
import { ButtonContainer, Container, Image, SubTitle, Title, Token } from "../styles/CommonComponents";

const Accordion = withStyles({
    root: {
        border: "none",
        boxShadow: "none",
        width: "100%",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "0",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "#f9f9f9",
        borderBottom: "none",
        marginBottom: -1,
        padding: 0,
        minHeight: 10,
        textAlign: "left",
        "&$expanded": {
            minHeight: 20,
        },
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
    },
    content: {
        "&$expanded": {
            margin: "0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: 0,
        backgroundColor: "#f9f9f9",
        textAlign: "left",
        margin: 0,
        flexDirection: "column",
        gap: "0.5rem",
    },
}))(MuiAccordionDetails);

const GenericError = () => {
    const { error_id } = useParams();

    const [errorDefault, setErrorDefault] = useState(false);

    const [errorBody, setErrorBody] = useState({ code: "", name: "", details: "", userHelp: [""] });

    useEffect(() => {
        console.log(error_id)
        switch (error_id) {
            case "523":
                setErrorBody({
                    code: "523",
                    name: "El origen es inalcanzable",
                    details: "No se puede acceder al servidor web de origen",
                    userHelp: [
                        "Por favor, intenta de nuevo en unos minutos. Si el problema persiste, puedes comunicarte con nuestro Centro de Ayuda.",
                    ],
                });
                break;

            case "522":
                setErrorBody({
                    code: "522",
                    name: "Tiempo de conexión agotado",
                    details:
                        "Se agotó el tiempo de espera de la conexión inicial entre la red de Cloudflare y el servidor web de origen. Como resultado, la página web no se puede mostrar",
                    userHelp: [
                        "Por favor, intenta de nuevo en unos minutos. Si el problema persiste, puedes comunicarte con nuestro Centro de Ayuda.",
                    ],
                });
                break;

            case "1015":
                setErrorBody({
                    code: "1015",
                    name: "Tu conexión está siendo limitada",
                    details: "El propietario de este sitio web te ha prohibido el acceso temporalmente",
                    userHelp: [
                        "Si eres un visitante del sitio, ponte en contacto con el propietario del sitio para solicitar la exclusión de la función de limitación de velocidad de tu IP",
                        "Si eres el propietario del sitio, revisa los umbrales de la función de Limitación de velocidad de Cloudflare y ajusta tu configuración",
                    ],
                });
                break;

            default:
                setErrorDefault(true);
                break;
        }
    }, [errorBody, error_id, errorDefault]);

    if (error_id === "500" || errorDefault) {
        return (
            <Container>
                <Image src={error500} alt='Imagen de error' />
                <Title>Ooops.. Parece que algo salió mal</Title>
                <Token>
                    {error_id.startsWith("5")
                        ? "::CLOUDFLARE_ERROR_500S_BOX::"
                        : error_id.startsWith("1")
                        ? "::CLOUDFLARE_ERROR_1000S_BOX::"
                        : ""}
                </Token>
                <SubTitle>
                    Perdona las molestias, estamos trabajando para solucionarlo. <br /> Intenta recargar esta página o
                    siéntete libre de contactarnos si el problema persiste.
                </SubTitle>
                <ButtonContainer>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            window.$crisp.push(["do", "chat:open"]);
                        }}
                    >
                        Abrir chat de soporte
                    </Button>
                </ButtonContainer>
            </Container>
        );
    }

    return (
        <CContainer>
            <CTextContainer>
                <CErrorType>ERROR {errorBody.code}</CErrorType>
                <CTitle>{errorBody.name}</CTitle>
                <Token>
                    {error_id.startsWith("5")
                        ? "::CLOUDFLARE_ERROR_500S_BOX::"
                        : error_id.startsWith("1")
                        ? "::CLOUDFLARE_ERROR_1000S_BOX::"
                        : ""}
                </Token>
                <CExpandContainer>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                        >
                            <CAccordionTitle>¿Qué ocurrió?</CAccordionTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            <CSubTitle>{errorBody.details}</CSubTitle>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel2a-content'
                            id='panel2a-header'
                        >
                            <CAccordionTitle>¿Qué puedo hacer?</CAccordionTitle>
                        </AccordionSummary>
                        <AccordionDetails>
                            {errorBody.userHelp.map((string) => {
                                return <CSubTitle>{string}</CSubTitle>;
                            })}
                        </AccordionDetails>
                    </Accordion>
                </CExpandContainer>

                <CButtonContainer>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            window.$crisp.push(["do", "chat:open"]);
                        }}
                    >
                        Abrir chat de soporte
                    </Button>
                </CButtonContainer>
            </CTextContainer>
            <CImageContainer>
                <CImage src={image} alt='Imagen de error' />
            </CImageContainer>
        </CContainer>
    );
};

export default GenericError;

const CContainer = styled.div`
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 2rem;
    width: 100%;
    padding: 2rem;
    gap: 1rem;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }
`;

const CTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;

    @media screen and (max-width: 768px) {
        align-items: center;
    }
`;

const CImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CImage = styled.img`
    width: 100%;
`;

const CErrorType = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 150%;
    color: #b31d15;
    @media screen and (max-width: 768px) {
        font-size: 1rem;
    }
`;

const CTitle = styled.h1`
    font-size: 3rem;
    font-weight: 400;
    color: #263238;
    text-align: left;
    margin: 0.5rem 0;

    @media screen and (max-width: 768px) {
        font-size: 2rem;
        text-align: center;
        margin: 0.1rem 0;
    }
`;

const CSubTitle = styled.p`
    font-size: 1.1rem;
    font-weight: 400;
    color: #616161;
    margin: 0.5rem 0;
`;

const CExpandContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;
    width: 100%;
`;

const CAccordionTitle = styled.div`
    font-size: 1.1rem;
    font-weight: 700;
    color: #616161;
`;

const CButtonContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
`;
