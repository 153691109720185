import styled from "styled-components";
import React from "react";
import Navbar from "../navbar/Navbar";

const Layout = (props) => {
    const { children } = props;

    return (
        <>
            <Navbar />
            <Main>{children}</Main>
        </>
    );
};

export default Layout;

const Main = styled.main`
    padding: 60px 10% 0 10%;
    background-color: #f9f9f9;
    min-height: 100vh;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

    transition: all 0.6s;

    @media (max-width: 1654px) {
        padding: 60px 10% 0 10%;
    }
    @media (max-width: 1440px) {
        padding: 60px 8% 0 8%;
    }

    @media (max-width: 768px) {
        padding: 60px 2rem 0 2rem;
    }
`;
